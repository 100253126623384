import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import StyledContainer from '../StyledContainer'

const SaveUrlBookmarklet: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const subHeaderClass = isMobile ? 'main-small-subheader' : 'main-subheader'

  //TODO: Add a endpoint so that it is possible to save the link and add a new task to the user.
  const bookmarkletCode = `
    (function() {
      const url = window.location.href;
      const title = document.title;
      window.open(
        'https://goachy.com/save-link?url=' + encodeURIComponent(url) + '&title=' + encodeURIComponent(title),
        'targetWindow',
        'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=500, height=500'
      );
    })();
    `

  return (
    <div className={'my-3'}>
      <StyledContainer>
        <div className={`${subHeaderClass} text-center my-2`}>
          <span>Save links bookmarklet</span>
        </div>
        <p className={'text-center'}>
          Drag the "Save to Goachy" button to your bookmarks bar to easily save
          links from any page you visit. Just click the bookmarklet whenever
          you're on a page you want to save!
        </p>
        <p>
          TODO: Add a endpoint so that it is possible to save the link.
          Add a video showing how to use the bookmarklet
          </p>
      </StyledContainer>

      <Container className={'mt-4'}>
          <Row className="justify-content-center">
            <Col>
              <a
                className="bookmarklet"
                href={`javascript:${encodeURIComponent(bookmarkletCode)}`}
                style={{
                  border: '1px solid black',
                  padding: '5px',
                  backgroundColor: 'yellow',
                  color: 'black',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                draggable="true"
              >
                Save
                </a>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default SaveUrlBookmarklet
