import React from 'react'

interface ClickableImageProps {
  src: string
  alt: string
  width?: string
  link: string
  title?: string
}

const ClickableImage: React.FC<ClickableImageProps> = ({
  src,
  alt,
  width,
  link,
  title,
}) => {
  const handleImageClick = () => {
    window.open(link, '_blank')
  }

  return (
    <div className="text-center mb-2" onClick={handleImageClick}>
      {/* TODO: different size for mobile */}
      <img
        style={{ cursor: 'pointer', width, borderRadius: '10px' }}
        src={src}
        alt={alt}
      />
      {title && <div className="mt-2">{title}</div>}
    </div>
  )
}

export default ClickableImage
